<template>
<div style="background-color: var(--background-color); height: 100vh; width: 100vw;">
    <b-container class="min-vh-100 d-flex flex-column">
      <b-row>
        <b-col cols="1" />
        <b-col>
          <h2 style="margin-bottom: 2rem; color: var(--white-color);">
            Passwort-Reset
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <b-input v-model="phone" class="mt-2" placeholder="Handynummer"/>
          <b-input v-model="pwd1" type="password" class="mt-2" placeholder="Neues-Passwort"/>
          <b-input v-model="pwd2" type="password" class="mt-2" placeholder="Neues-Passwort bestätigen"/>
          <b-button @click="resetPwd" class="mt-2">Passwort ändern</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import sha512 from 'js-sha512';
export default {
  name: 'Reset',
  data() {
    return {
      phone: null,
      pwd1: null,
      pwd2: null
    };
  },
  methods: {
    async resetPwd() {
      if (!this.phone || !this.pwd1 || !this.pwd1 || this.pwd1 !== this.pwd2) {
        this.toast('Fehler', 'Ungültige Eingabe', 'danger');
        return;
      }
      const hashStr = `${this.phone}:resetPwd`;
      const hash = await sha512(hashStr);
      this.axios.post('auth/resetPassword', {
        hash,
        phone: this.phone,
        token: this.$route.query.token,
        pwd: this.pwd1
      })
      .then((res) => {
        if (res.status === 200) this.toast('Erfolg', 'Passwort erfolgreich geändert', 'success')
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response.data === 'token expired') return this.toast('Fehler', 'Der Reset-Link ist abgelaufen', 'danger');
        this.toast('Fehler', 'Es ist ein Fehler aufgetreten', 'danger')
      });
    }
  }
}
</script>

<style>

</style>